import { useState, useEffect, useCallback, useRef } from 'react';

export const useOverlay = () => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);

  const openOverlay = useCallback(() => {
    setIsOverlayOpen(true);
  }, []);

  const closeOverlay = useCallback(() => {
    setIsOverlayOpen(false);
  }, []);

  useEffect(() => {
    const handleTriggerClick = (e: MouseEvent) => {
      const target = e.target as Element;
      if (target.closest('.overlay-trigger')) {
        openOverlay();
      }
    };

    const currentTriggerRef = triggerRef.current;

    if (currentTriggerRef) {
      currentTriggerRef.addEventListener('click', handleTriggerClick);
    }

    return () => {
      if (currentTriggerRef) {
        currentTriggerRef.removeEventListener('click', handleTriggerClick);
      }
    };
  }, [openOverlay]);

  return { isOverlayOpen, closeOverlay, triggerRef };
};



{/* import { useState, useEffect, useCallback } from 'react';

export const useOverlay = () => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const openOverlay = useCallback(() => {
    setIsOverlayOpen(true);
  }, []);

  const closeOverlay = useCallback(() => {
    setIsOverlayOpen(false);
  }, []);

  useEffect(() => {
    const handleTriggerClick = (e: MouseEvent) => {
      if ((e.target as Element).closest('.overlay-trigger')) {
        openOverlay();
      }
    };

    document.addEventListener('click', handleTriggerClick);

    return () => {
      document.removeEventListener('click', handleTriggerClick);
    };
  }, [openOverlay]);

  return { isOverlayOpen, closeOverlay };
};
*/}