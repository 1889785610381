"use client"


import React from "react";
// import { Footer } from "../../../components/Footer";
// import { Nav } from "../../../components/Nav";
// import "./style.css";

const Call = (): JSX.Element => {
  return (
    <div className="call">
      <div className="call-wrapper">
        <div className="div-16">
          <div className="group-44">
            <div className="group-45">
              <img className="arrow-9" alt="Arrow" src="/img/arrow-11.svg" />
            </div>
            <div className="submit-4">SUBMIT</div>
          </div>
          <div className="get-in-touch">Get In Touch</div>
          <div className="overlap-28">
            <div className="text-wrapper-153">Sales</div>
          </div>
          <div className="overlap-29">
            <div className="text-wrapper-154">Support</div>
          </div>
          <div className="IPM-wrapper">
            <div className="IPM">Ipm</div>
          </div>
          <div className="group-wrapper">
            {/* <div className="group-46">
              <div className="phone-wrapper">
                <div className="phone">Phone:&nbsp;&nbsp;222-334-4556</div>
              </div>
            </div> */}
          </div>
          <input className="first-name-3" placeholder="First Name" type="text" />
          <input className="last-name-4" placeholder="Last Name" type="text" />
          <img className="line-81" alt="Line" src="/img/line-24-2.svg" />
          <img className="line-82" alt="Line" src="/img/line-24-2.svg" />
          <div className="phone-number-4">Phone Number</div>
          <img className="line-83" alt="Line" src="/img/line-19-1.svg" />
          <div className="email-address-4">Email Address</div>
          <img className="line-84" alt="Line" src="/img/line-23-3.svg" />
          <div className="location-state">Location/state:</div>
          <img className="line-85" alt="Line" src="/img/line-28.svg" />
          <img className="vector-6" alt="Vector" src="/img/vector-2.svg" />
          <div className="interested-in-4">Interested In:</div>
          <img className="vector-7" alt="Vector" src="/img/vector-2.svg" />
          <img className="line-86" alt="Line" src="/img/line-28.svg" />
          <div className="message-4">Message:</div>
          <img className="line-87" alt="Line" src="/img/line-22-3.svg" />
          <img className="line-88" alt="Line" src="/img/line-27.svg" />
          <div className="group-47">
            <div className="ph-x-wrapper">
              <img className="ph-x" alt="Ph x" src="/img/ph-x.svg" />
            </div>
          </div>
        </div>
        {/* <Nav className="NAV-instance" line="/img/line-2-17.svg" to="/screens/mobile/Menu-Mobile/" />
        <Footer className="FOOTER-instance" antDesignInstagram="/img/ant-design-instagram-filled-18.svg"
            antDesignYoutube="/img/ant-design-youtube-filled-17.svg"
            flowbiteXSolid="/img/flowbite-x-solid-16.svg"
            icBaselineTiktok="/img/ic-baseline-tiktok-16.svg"
        /> */}
      </div>
    </div>
  );
};

export default Call;

