// utils/analytics.js
import ReactGA from 'react-ga4'
import { hotjar } from 'react-hotjar'
import * as amplitude from '@amplitude/analytics-browser';

const HOTJAR_SITE_ID: number = Number(process.env.HOTJAR_ID) // Convert to number
const AMPLITUDE_API_KEY: string = process.env.AMPLITUDE_API_KEY || '' // Convert to number
const HOTJAR_VERSION = 6

export const initGA = () => {
  const trackingId: string | undefined = process.env.GA4_TRACKING_ID;
  if (trackingId) {
    ReactGA.initialize(trackingId);
  }
}

export const logPageView = () => {
  hotjar.stateChange(window.location.pathname);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "router change" });
  amplitude.track('pageView', { path: window.location.pathname });
}

export const initHotjar = () => {
  hotjar.initialize({id:HOTJAR_SITE_ID, sv: HOTJAR_VERSION})
  // Check if Hotjar has been initialized before calling its methods
  if (hotjar.initialized()) {
    hotjar.identify('USER_ID', { userProperty: 'value' });
  }
}

export const initAmplitude = () => {
  amplitude.init(AMPLITUDE_API_KEY, {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
      fileDownloads: true,
    },
  });
};

// // utils/analytics.js
// import ReactGA from 'react-ga4'
// import { hotjar } from 'react-hotjar'
// import * as amplitude from '@amplitude/analytics-browser';

// const HOTJAR_SITE_ID: number = Number(process.env.HOTJAR_ID) // Convert to number
// const AMPLITUDE_API_KEY: string = process.env.AMPLITUDE_API_KEY || '' // Convert to number
// const HOTJAR_VERSION = 6

// export const initGA = () => {
//   const trackingId: string | undefined = process.env.GA4_TRACKING_ID;
//   if (trackingId) {
//     ReactGA.initialize(trackingId);
//   }
// }

// export const logPageView = () => {
//   hotjar.stateChange(window.location.pathname);
//   ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "router change" });
//   amplitude.track('pageView', { path: window.location.pathname });
// }

// export const initHotjar = () => {
//   hotjar.initialize({id:HOTJAR_SITE_ID, sv: HOTJAR_VERSION})
//   // Check if Hotjar has been initialized before calling its methods
//   if (hotjar.initialized()) {
//     hotjar.identify('USER_ID', { userProperty: 'value' });
//   }
// }

// export const initAmplitude = () => {
//   amplitude.init(AMPLITUDE_API_KEY, {
//     defaultTracking: {
//       sessions: true,
//       pageViews: true,
//       formInteractions: true,
//       fileDownloads: true,
//     },
//   });
// };