import React, { ReactNode } from 'react';
import { useOverlay } from './useOverlay';
import Call from '../Call/page'; // Adjust the import path if necessary

interface GlobalOverlayProps {
  children: ReactNode;
}

export const GlobalOverlay: React.FC<GlobalOverlayProps> = ({ children }) => {
  const { isOverlayOpen, closeOverlay, triggerRef } = useOverlay();

  return (
    <>
      <div ref={triggerRef}>
        {children}
      </div>
      {isOverlayOpen && (
        <div className="overlay">
          <div className="overlay-content">
            <button className="overlay-close" onClick={closeOverlay}>X</button>
            <Call />
          </div>
        </div>
      )}
    </>
  );
};


{/*}
// import React from 'react';
// import { useOverlay } from './useOverlay';
// import Call from '../Call/page'; // Adjust the import path if necessary

// export const GlobalOverlay: React.FC = () => {
//   const { isOverlayOpen, closeOverlay } = useOverlay();

//   if (!isOverlayOpen) {
//     return null;
//   }

//   return (
//     <div className="overlay">
//       <div className="overlay-content">
//         <button className="overlay-close" onClick={closeOverlay}>X</button>
//         <Call />
//       </div>
//     </div>
//   );
// };

*/}