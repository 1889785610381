
// pages/_app.tsx
import React,{ FC, useEffect, useState } from 'react';
import Head from 'next/head';
import { GlobalOverlay } from '../components/GlobalOverlay/GlobalOverlay';
import type { AppProps } from 'next/app';
import Layout from '@components/common/Layout';
import { builder, Builder } from '@builder.io/react';
import builderConfig from '@config/builder';
import "./customStyles.css";
import "../components/SlideInGood-2/SlideInGood.module.css";
import '../node_modules/@trycreo/ui/dist/index.css';
import { Analytics } from "@vercel/analytics/react"



builder.init(builderConfig.apiKey);

import '@builder.io/widgets';
import '../blocks/ProductGrid/ProductGrid.builder';
import '../blocks/CollectionView/CollectionView.builder';
import '../blocks/ProductView/ProductView.builder';
import '../blocks/CloudinaryImage/CloudinaryImage.builder';
import '../blocks/AddToCart/AddToCartButton.builder';
import { initAmplitude, initGA, initHotjar, logPageView } from '@lib/analitics';
import { useRouter } from 'next/router';

Builder.register('insertMenu', {
  name: 'Shopify Collections Components',
  items: [
    { name: 'CollectionBox', label: 'Collection stuff' },
    { name: 'ProductCollectionGrid' },
    { name: 'CollectionView' },
  ],
});

Builder.register('insertMenu', {
  name: 'Shopify Products Components',
  items: [
    { name: 'ProductGrid' },
    { name: 'ProductBox' },
    { name: 'ProductView' },
    { name: 'AddToCartButton' },

  ],
});

Builder.register('insertMenu', {
  name: 'Cloudinary Components',
  items: [{ name: 'CloudinaryImage' }],
});

const Noop: FC<{ children: React.ReactNode }> = ({ children }) => <>{children}</>;

export default function MyApp({ Component, pageProps }: AppProps) {

  const Router = useRouter();
  const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false)

  useEffect(() => {
    if (!isAnalyticsInitialized) {
      initGA()
      initHotjar()
      initAmplitude()
      setIsAnalyticsInitialized(true)
      logPageView()
    }
    // Log page view on route change
    const handleRouteChange = () => logPageView()
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])


  return (
    <>
    <Head>
      <link href="https://fonts.googleapis.com/css2?family=Basic&display=swap" rel="stylesheet" />
    </Head>
    <GlobalOverlay>

    <Layout pageProps={pageProps}>
            {/* <OverlayMenu model="page" path="/menu" /> */}
            {/* <SlideInGood /> */}


            {/* <SlideInMenu model="page" path="/contact-mobile" /> */}
            {/* <Nav className="NAV-instance" line="/img/line-2-17.svg" to="/screens/mobile/Menu-Mobile/" /> */}

      {/* <OverlayMenu model="page" contentId="c3e771d499ac4f2381f0731eb06d1425" /> */}
      <Analytics />
      <Component {...pageProps} />
    </Layout>
    </GlobalOverlay>
</>
  );
}


// //last working, no analytics

// // pages/_app.tsx
// import React,{ FC, useEffect, useState } from 'react';
// import Head from 'next/head';
// import { GlobalOverlay } from '../components/GlobalOverlay/GlobalOverlay';
// import type { AppProps } from 'next/app';
// import Layout from '@components/common/Layout';
// import { builder, Builder } from '@builder.io/react';
// import builderConfig from '@config/builder';
// import "./customStyles.css";
// import "../components/SlideInGood-2/SlideInGood.module.css";
// import '../node_modules/@trycreo/ui/dist/index.css';
// import { Analytics } from "@vercel/analytics/react"



// builder.init(builderConfig.apiKey);

// import '@builder.io/widgets';
// import '../blocks/ProductGrid/ProductGrid.builder';
// import '../blocks/CollectionView/CollectionView.builder';
// import '../blocks/ProductView/ProductView.builder';
// import '../blocks/CloudinaryImage/CloudinaryImage.builder';
// import '../blocks/AddToCart/AddToCartButton.builder';
// import { initAmplitude, initGA, initHotjar, logPageView } from '@lib/analytics';
// import { useRouter } from 'next/router';




// Builder.register('insertMenu', {
//   name: 'Shopify Collections Components',
//   items: [
//     { name: 'CollectionBox', label: 'Collection stuff' },
//     { name: 'ProductCollectionGrid' },
//     { name: 'CollectionView' },
//   ],
// });

// Builder.register('insertMenu', {
//   name: 'Shopify Products Components',
//   items: [
//     { name: 'ProductGrid' },
//     { name: 'ProductBox' },
//     { name: 'ProductView' },
//     { name: 'AddToCartButton' },

//   ],
// });

// Builder.register('insertMenu', {
//   name: 'Cloudinary Components',
//   items: [{ name: 'CloudinaryImage' }],
// });

// const Noop: FC<{ children: React.ReactNode }> = ({ children }) => <>{children}</>;

// export default function MyApp({ Component, pageProps }: AppProps) {


//               const Router = useRouter();
//               const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false)

//               useEffect(() => {
//                 if (!isAnalyticsInitialized) {
//                   initGA()
//                   initHotjar()
//                   initAmplitude()
//                   setIsAnalyticsInitialized(true)
//                   logPageView()
//                 }
//                 // Log page view on route change
//                 const handleRouteChange = () => logPageView()
//                 Router.events.on('routeChangeComplete', handleRouteChange)
//                 return () => {
//                   Router.events.off('routeChangeComplete', handleRouteChange)
//                 }
//               }, [])
          
//   return (
//     <>
//     <Head>
//       <link href="https://fonts.googleapis.com/css2?family=Basic&display=swap" rel="stylesheet" />
//     </Head>
//     <GlobalOverlay>

//     <Layout pageProps={pageProps}>
//             {/* <OverlayMenu model="page" path="/menu" /> */}
//             {/* <SlideInGood /> */}


//             {/* <SlideInMenu model="page" path="/contact-mobile" /> */}
//             {/* <Nav className="NAV-instance" line="/img/line-2-17.svg" to="/screens/mobile/Menu-Mobile/" /> */}

//       {/* <OverlayMenu model="page" contentId="c3e771d499ac4f2381f0731eb06d1425" /> */}
//       <Analytics />
//       <Component {...pageProps} />
//     </Layout>
//     </GlobalOverlay>
// </>
//   );
// }



// // pages/_app.tsx
// import { FC } from 'react';
// import type { AppProps } from 'next/app';
// import Layout from '@components/common/Layout';
// import { builder, Builder } from '@builder.io/react';
// import builderConfig from '@config/builder';
// import OverlayMenu from '../components/OverlayMenu/OverlayMenu';

// builder.init(builderConfig.apiKey);

// import '@builder.io/widgets';
// import '../blocks/ProductGrid/ProductGrid.builder';
// import '../blocks/CollectionView/CollectionView.builder';
// import '../blocks/ProductView/ProductView.builder';
// import '../blocks/CloudinaryImage/CloudinaryImage.builder';

// Builder.register('insertMenu', {
//   name: 'Shopify Collections Components',
//   items: [
//     { name: 'CollectionBox', label: 'Collection stuff' },
//     { name: 'ProductCollectionGrid' },
//     { name: 'CollectionView' },
//   ],
// });

// Builder.register('insertMenu', {
//   name: 'Shopify Products Components',
//   items: [
//     { name: 'ProductGrid' },
//     { name: 'ProductBox' },
//     { name: 'ProductView' },
//   ],
// });

// Builder.register('insertMenu', {
//   name: 'Cloudinary Components',
//   items: [{ name: 'CloudinaryImage' }],
// });

// const Noop: FC<{ children: React.ReactNode }> = ({ children }) => <>{children}</>;

// export default function MyApp({ Component, pageProps }: AppProps) {
//   return (
//     <Layout pageProps={pageProps}>
//       <OverlayMenu model="page" contentId="c3e771d499ac4f2381f0731eb06d1425" />
//       <Component {...pageProps} />
//     </Layout>
//   );
// }


// // // pages/_app.tsx
// // import { FC } from 'react';
// // import type { AppProps } from 'next/app';
// // import Layout from '@components/common/Layout';
// // import { builder, Builder } from '@builder.io/react';
// // import builderConfig from '@config/builder';
// // import OverlayMenu from '../components/OverlayMenu/OverlayMenu';

// // builder.init(builderConfig.apiKey);

// // import '@builder.io/widgets';
// // import '../blocks/ProductGrid/ProductGrid.builder';
// // import '../blocks/CollectionView/CollectionView.builder';
// // import '../blocks/ProductView/ProductView.builder';
// // import '../blocks/CloudinaryImage/CloudinaryImage.builder';

// // Builder.register('insertMenu', {
// //   name: 'Shopify Collections Components',
// //   items: [
// //     { name: 'CollectionBox', label: 'Collection stuff' },
// //     { name: 'ProductCollectionGrid' },
// //     { name: 'CollectionView' },
// //   ],
// // });

// // Builder.register('insertMenu', {
// //   name: 'Shopify Products Components',
// //   items: [
// //     { name: 'ProductGrid' },
// //     { name: 'ProductBox' },
// //     { name: 'ProductView' },
// //   ],
// // });

// // Builder.register('insertMenu', {
// //   name: 'Cloudinary Components',
// //   items: [{ name: 'CloudinaryImage' }],
// // });

// // const Noop: FC<{ children: React.ReactNode }> = ({ children }) => <>{children}</>;

// // export default function MyApp({ Component, pageProps }: AppProps) {
// //   return (
// //     <Layout pageProps={pageProps}>
// //       <OverlayMenu model="page" path="/about-us" />
// //       <Component {...pageProps} />
// //     </Layout>
// //   );
// // }


// // // import { FC } from 'react'
// // // import type { AppProps } from 'next/app'
// // // import Layout from '@components/common/Layout'
// // // import { builder, Builder } from '@builder.io/react'
// // // import builderConfig from '@config/builder'
// // // import BuilderContent from '../components/BuilderContent';




// // // builder.init(builderConfig.apiKey)

// // // import '@builder.io/widgets'
// // // import '../blocks/ProductGrid/ProductGrid.builder'
// // // import '../blocks/CollectionView/CollectionView.builder'
// // // import '../blocks/ProductView/ProductView.builder'
// // // import '../blocks/CloudinaryImage/CloudinaryImage.builder'
// // // import SlideInMenu from '../components/SlideInMenu/SlideInMenu';
// // // import OverlayMenu from '../components/OverlayMenu/OverlayMenu';

// // // Builder.register('insertMenu', {
// // //   name: 'Shopify Collections Components',
// // //   items: [
// // //     { name: 'CollectionBox', label: 'Collection stuff' },
// // //     { name: 'ProductCollectionGrid' },
// // //     { name: 'CollectionView' },
// // //   ],
// // // })

// // // Builder.register('insertMenu', {
// // //   name: 'Shopify Products Components',
// // //   items: [
// // //     { name: 'ProductGrid' },
// // //     { name: 'ProductBox' },
// // //     { name: 'ProductView' },
// // //   ],
// // // })

// // // Builder.register('insertMenu', {
// // //   name: 'Cloudinary Components',
// // //   items: [{ name: 'CloudinaryImage' }],
// // // })

// // // const Noop: FC<{ children: React.ReactNode }> = ({ children }) => (
// // //   <>{children}</>
// // // )

// // // export default function MyApp({ Component, pageProps }: AppProps) {
// // //   return (
// // //     <Layout pageProps={pageProps}>
// // //     {/* <SlideInMenu /> */}
// // //     {/* <SlideInMenu model="page" contentId="4b669b0f209f4436bc4ce7ea1c2d493c" /> */}
// // //     <SlideInMenu model="page" path="/menu" />
// // //     <OverlayMenu model="page" path="/menu" />
// // //     {/* <BuilderContent model="header" /> */}

// // //       <Component {...pageProps} />
// // //     </Layout>
// // //   )
// // // }
