import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

const LazyAddToCartButton = dynamic(() => import('./AddToCartButton'), {
  ssr: false,
})

Builder.registerComponent(LazyAddToCartButton, {
  name: 'AddToCartButton',
  inputs: [
    {
      name: 'productId',
      type: 'string',
      required: true,
    },
    {
      name: 'variantId',
      type: 'string',
    },
    {
      name: 'quantity',
      type: 'number',
      defaultValue: 1,
    },
    {
      name: 'text',
      type: 'string',
      defaultValue: 'Add to Cart',
    },
  ],
})

// import { Builder } from '@builder.io/react'
// import dynamic from 'next/dynamic'

// const LazyAddToCartButton = dynamic(() => import('./AddToCartButton'), {
//   ssr: false,
// })

// Builder.registerComponent(LazyAddToCartButton, {
//   name: 'AddToCartButton',
//   inputs: [
//     {
//       name: 'variantId',
//       type: 'string',
//       required: true,
//     },
//     {
//       name: 'quantity',
//       type: 'number',
//       defaultValue: 1,
//     },
//     {
//       name: 'text',
//       type: 'string',
//       defaultValue: 'Add to Cart',
//     },
//   ],
// })